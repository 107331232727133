import React from "react"

export default () =>

<div className="ui vertical stripe segment">
      <div class="ui info message">
        Hudub will be launched soon. Fill in the form below and we will contact you.
      </div>
      <div class="ui divider" />
  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeMblYdtuJH7FSl6lo0bsvgziW3gp8Tp2Dpb3tR_jjCkq_AoQ/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>

</div>
